import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Features } from '@app/enums';
import { FeatureService } from '@app/startup/feature.service';
import { Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { contractInfoPerEmployeePerDay, getContracts } from '@reducers/orm/contract/contract.service';
import { getEmployabilityEntities } from '@reducers/orm/employability/employability.selector';
import { EmployeeModel } from '@reducers/orm/employee/employee.model';
import { getEmployeeEntities } from '@reducers/orm/employee/employee.service';
import { EmployeeStatusModel, OpenShiftUserStatus } from '@reducers/orm/open-shift/open-shift.model';
import { EmployeeSelectItemViewModel } from '@shared/schedule-employee/employee-select-items.model';
import { selectEmployeeSelectItemsViewModel } from '@shared/schedule-employee/employee-select-items.selector';
import get from 'lodash-es/get';
import mapValues from 'lodash-es/mapValues';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { OpenShiftStatusFilterTypes } from './sb-table-select.model';

export interface TableSelectFilter {
  value: string;
  name: string;
}

@Component({
  selector: 'sb-table-select',
  templateUrl: 'sb-table-select.component.html',
  styleUrls: ['sb-table-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbTableSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public employees: EmployeeModel[];

  @Input()
  public instancesRemaining: number;
  @Input()
  public date: string;
  @Input()
  public startTime: string;
  @Input()
  public endTime: string;
  @Input()
  public shiftId: string;
  @Input()
  public EmployeeStatusEntities: Record<string, EmployeeStatusModel> = {};
  @Input()
  public height = 510;

  @Input()
  public assignTmp: TemplateRef<any>;
  @Input()
  public filters: TableSelectFilter[] = [];
  @Input()
  private enabledFilters = [];

  public checkboxDisabled: boolean;

  public filterGroup = new FormGroup<{ [key: string]: FormControl<boolean> }>({});

  public searchCtrl = new FormControl('');

  public filteredRows: EmployeeModel[] = [];

  private dataSubs = new Subscription();

  @Output()
  public selectionChanged = new EventEmitter<EmployeeModel[]>();

  public selected: EmployeeModel[] = [];
  public selectedEntities: Record<string, boolean> = {};
  public features = Features;

  public employeeOptions: Record<string, EmployeeSelectItemViewModel> = {};

  public constructor(
    private store: Store<AppState>,
    private readonly feature: FeatureService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(this.getEmployees());

    this.filters.map((filter) =>
      this.filterGroup.addControl(
        filter.value.toString(),
        new FormControl(this.enabledFilters.includes(filter.value.toString())),
      ),
    );

    this.dataSubs.add(
      this.filterGroup.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.filterRows();
      }),
    );

    this.dataSubs.add(
      this.searchCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.filterRows();
      }),
    );

    this.filterRows();
  }

  private filterRows() {
    const searchValue = this.searchCtrl.value.toLocaleLowerCase();
    const activeFilters = this.filterGroup.value;
    this.filteredRows = this.employees.filter((employee) => {
      const employeeStatus = this.EmployeeStatusEntities?.[employee.id]?.status;
      if (!activeFilters[OpenShiftStatusFilterTypes.NOT_INVITED] && !employeeStatus) {
        return false;
      }

      if (!activeFilters[OpenShiftStatusFilterTypes.PENDING] && employeeStatus === OpenShiftUserStatus.PENDING) {
        return false;
      }

      if (!activeFilters[OpenShiftStatusFilterTypes.ASSIGNED] && employeeStatus === OpenShiftUserStatus.ASSIGNED) {
        return false;
      }

      if (!activeFilters[OpenShiftStatusFilterTypes.DECLINED] && employeeStatus === OpenShiftUserStatus.DECLINED) {
        return false;
      }

      if (!activeFilters[OpenShiftStatusFilterTypes.REQUESTED] && employeeStatus === OpenShiftUserStatus.REQUESTED) {
        return false;
      }

      if (!!searchValue && !employee.name.toLocaleLowerCase().includes(searchValue)) {
        return false;
      }

      // If the employee has requested the shift but is unavailable, we should show them regardless of the availability filter
      if (activeFilters[OpenShiftStatusFilterTypes.REQUESTED] && employeeStatus === OpenShiftUserStatus.REQUESTED) {
        return true;
      }

      if (
        !activeFilters?.[OpenShiftStatusFilterTypes.INCOMPATIBLE_EMPLOYEES] &&
        !!this.employeeOptions[employee.id]?.employability &&
        !this.employeeOptions[employee.id]?.employability?.employable
      ) {
        return false;
      }

      return true;
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['instancesRemaining']) {
      this.selected = [];
    }

    if (changes['employees'] || changes['EmployeeStatusEntities']) {
      this.filterRows();
    }
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  private getEmployees() {
    // todo subscribe to selectEmployeeSelectItemsViewModel directly when removing feature flag TMP_REPLACE_WAGE_WITH_CTC
    return this.feature
      .isEnabled$(Features.TMP_REPLACE_WAGE_WITH_CTC)
      .pipe(
        switchMap((replaceWageWithCtc) => {
          if (replaceWageWithCtc) {
            return this.store.select(selectEmployeeSelectItemsViewModel(this.date));
          }
          // todo: remove all this when cleanup feature flag TMP_REPLACE_WAGE_WITH_CTC
          return combineLatest([
            this.store.select(getEmployeeEntities),
            this.store.select(getContracts),
            this.store.select(getEmployabilityEntities),
          ]).pipe(
            map(([employees, contracts, employability]) => {
              const contractPerDay = contractInfoPerEmployeePerDay(this.date, this.date, contracts);

              return mapValues(employees, (employee: EmployeeModel) => {
                const wage = get(contractPerDay, `${employee.id}.${this.date}.wage`, '0.00') as string;
                const employeeEmployability = employability[employee.id];
                const onlyLackSkills =
                  !employeeEmployability?.employable &&
                  !!employeeEmployability?.details &&
                  !employeeEmployability?.details?.schedule?.length &&
                  !employeeEmployability?.details?.absence?.length &&
                  !employeeEmployability?.details?.availability;
                return {
                  ...employee,
                  wage,
                  cost: wage,
                  employability: employeeEmployability,
                  onlyLackSkills,
                };
              });
            }),
          );
        }),
      )
      .subscribe((employeeOptions) => {
        this.employeeOptions = employeeOptions;
        this.filterRows();
      });
  }

  public selectEmployee(event: Event, employee: EmployeeModel) {
    if (this.checkboxDisabled && !this.selectedEntities[employee.id]) {
      return;
    }

    if (this.selectedEntities[employee.id]) {
      this.selected = this.selected.filter((e) => e.id !== employee.id);
      this.selectionChange(this.selected);
      return;
    }
    this.selected = [...this.selected, employee];
    this.selectionChange(this.selected);
  }

  public selectionChange(employees: EmployeeModel[]) {
    this.selectedEntities = {};
    employees.forEach((employee) => {
      this.selectedEntities[employee.id] = true;
    });
    this.updateCheckboxState();
    this.selectionChanged.emit(employees);
  }

  private updateCheckboxState() {
    this.checkboxDisabled = this.instancesRemaining <= this.selected.length;
  }

  public trackBy(_: number, filter: TableSelectFilter): string {
    return filter.value;
  }
}
