import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { OnboardingUtils } from '@app/+onboarding/onboarding.utils';
import { getPermissionState } from '@app/reducers/auth/permission.helper';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { UserGuideModel, UserGuideStatus, UserGuideType } from '@app/reducers/orm/user-guide/user-guide.model';
import { getUserGuideEntities, isUserGuideFinished } from '@app/reducers/orm/user-guide/user-guide.selector';
import { UserGuideService } from '@app/reducers/orm/user-guide/user-guide.service';
import { getPageParamsState } from '@app/reducers/page-params-state/page-params.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChecklistItemModel, ChecklistModel, SbDialogService } from '@sb/ui';
import { roundToDecimal } from '@shiftbase-com/utilities';
import { BehaviorSubject, filter, map, Observable, switchMap, take, withLatestFrom } from 'rxjs';

import { Features } from '../../enums';
import { AppState } from '../../reducers';
import { getSelfOnboarding } from '../../reducers/account/account.service';
import { FeatureService } from '../../startup/feature.service';
import {
  ManagerOnboardingChecklistStep,
  OnboardingChecklistStep,
  SmbHospitalityOnboardingChecklistStep,
  SmbHospitalityOnboardingChecklistStepKeys,
} from './onboarding-checklist.model';

interface UserGuideModelEnchanced extends UserGuideModel {
  completedSteps: OnboardingChecklistStep[];
}

type SupportedUserGuideTypes = UserGuideType.MANAGER_ONBOARDING_CHECKLIST | UserGuideType.SELF_ONBOARDING_CHECKLIST;

@Injectable({
  providedIn: 'root',
})
export class OnboardingChecklistService {
  public totalSteps: number;

  public authUser$ = this.store.select(getAuthenticatedUser);
  public scheduleLink: string[] | any[];

  public showChecklist: boolean;

  private userGuideType: UserGuideType;
  private userGuide$: Observable<UserGuideModelEnchanced> = this.store.select(getUserGuideEntities).pipe(
    map((userGuideEntities) => userGuideEntities[this.userGuideType]),
    map((guide) => {
      if (guide && guide.status === UserGuideStatus.IN_PROGRESS) {
        return {
          ...guide,
          completedSteps: this.transformUserGuideStepToChecklistSteps(guide.step),
        };
      }
      return null;
    }),
  );

  public isChecklistInProgress$: Observable<boolean> = this.userGuide$.pipe(
    withLatestFrom(this.store.select(getPermissionState)),
    map(([guide, permissionState]) => {
      if (!permissionState.isAccountManager) {
        return false;
      }

      if (!guide) {
        return false;
      }

      return guide.status === UserGuideStatus.IN_PROGRESS;
    }),
  );

  public completedSteps$ = new BehaviorSubject<OnboardingChecklistStep[]>(undefined);

  public get completedSteps(): OnboardingChecklistStep[] | undefined {
    return this.completedSteps$.value;
  }

  /**
   * TODO get rid of this thing. This isn't pure at all.
   * - Progress and remainingSteps can just be calculated in checklist component itself
   * - Labels maybe even as well as there probably isn't any other labels envisioned as the ones composed in authenticated component
   * - scheduleLink is only depending on pageParams and can be determined completely separately from fetching the guide
   * - The name of this observable doesn't make sense either, it doesn't return items, it returns a checklist model
   */

  public items$: Observable<ChecklistModel> = this.userGuide$.pipe(
    withLatestFrom(
      this.store.select(getPermissionState),
      this.store.select(getPageParamsState),
      this.store.pipe(select(isUserGuideFinished(UserGuideType.SIGNUP_GREETING_MODAL))),
    ),
    map(([guide, permissionState, pageParams, signupGreetingFinished]) => {
      if (pageParams) {
        this.scheduleLink = [
          'schedule',
          pageParams.schedule.mode,
          pageParams.schedule.period,
          { date: pageParams.schedule.date },
        ];
      }

      this.completedSteps$.next(guide?.completedSteps);
      const items = this.items;
      this.totalSteps = items.length;

      if (!guide) {
        return null;
      }

      // TODO manager onboarding specific logic. Should be moved out of it.
      if (this.userGuideType === UserGuideType.MANAGER_ONBOARDING_CHECKLIST) {
        if (permissionState.isAccountManager === false) return null;
        if (signupGreetingFinished) {
          this.showChecklist = true;
        }
      } else {
        this.showChecklist = true;
      }

      const progress = roundToDecimal((this.completedSteps.length / this.totalSteps) * 100, 2);
      const remainingSteps = this.totalSteps - this.completedSteps.length;
      return {
        progress,
        items,
        remainingSteps,
      };
    }),
  );

  public constructor(
    private readonly store: Store<AppState>,
    private readonly userGuideService: UserGuideService,
    private readonly translate: TranslateService,
    private readonly dialog: SbDialogService,
    private readonly featureService: FeatureService,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly onboardingUtils: OnboardingUtils,
  ) {
    void this.store
      .select(getSelfOnboarding)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selfOnboarding) => {
        if (this.featureService.isFeatureActivated(Features.TMP_SMB_NL_HOSPITALITY_ONBOARDING) && !!selfOnboarding) {
          this.userGuideType = UserGuideType.SELF_ONBOARDING_CHECKLIST;
        } else {
          this.userGuideType = UserGuideType.MANAGER_ONBOARDING_CHECKLIST;
        }
      });
  }

  public initGuide(guide: SupportedUserGuideTypes): void {
    void this.userGuideService
      .trackUserGuide({
        guide,
        status: UserGuideStatus.IN_PROGRESS,
        // TODO manager onboarding specific logic. Should be moved out of it. Signup component should mark this step as completed.
        step:
          guide === UserGuideType.MANAGER_ONBOARDING_CHECKLIST ? ManagerOnboardingChecklistStep.COMPLETE_SIGNUP : '',
      })
      .pipe(take(1))
      .subscribe();
  }

  public completeStep(guide: UserGuideType, step: OnboardingChecklistStep): void {
    // Right now it is only possible to have one onboarding checklist guide active at a time.
    // Only mark the step as completed if it is part of the active guide.
    if (!this.completedSteps || this.completedSteps.includes(step) || guide !== this.userGuideType) {
      return;
    }

    const completedSteps = [...this.completedSteps, step];
    const guideStatus =
      completedSteps.length === this.totalSteps ? UserGuideStatus.FINISHED : UserGuideStatus.IN_PROGRESS;

    void this.userGuideService
      .trackUserGuide({
        guide: this.userGuideType,
        status: guideStatus,
        // TODO why would a finished guide reset its completed steps. If we ever were to add more steps to the guide, the user would be shown all of them as unfinished again.
        step:
          guideStatus !== UserGuideStatus.FINISHED ? this.transformChecklistStepsToUserGuideStep(completedSteps) : '',
      })
      .subscribe();
  }

  public dismiss(): Observable<void> {
    return this.dialog
      .openConfirm({
        type: 'warning',
        title: this.translate.instant('Dismiss setup guide?'),
        description: this.translate.instant(
          'If you dismiss it now, you might miss out on valueable tips and insights that can streamline your journey.',
        ),
        primary: {
          text: this.translate.instant('Dismiss'),
        },
      })
      .closed.pipe(
        filter((result) => result?.confirmed),
        switchMap(() =>
          this.userGuideService.trackUserGuide({
            guide: this.userGuideType,
            status: UserGuideStatus.DISMISSED,
            step: null,
          }),
        ),
      );
  }

  private isCompletedStep(step: OnboardingChecklistStep): boolean {
    return this.completedSteps.includes(step);
  }

  private get items(): ChecklistItemModel[] {
    switch (this.userGuideType) {
      case UserGuideType.MANAGER_ONBOARDING_CHECKLIST:
        return this.managerOnboardingChecklistItems;
      case UserGuideType.SELF_ONBOARDING_CHECKLIST:
        return this.selfOnboardingChecklistItems;
      default:
        return [];
    }
  }

  private get managerOnboardingChecklistItems(): ChecklistItemModel[] {
    return [
      {
        title: this.translate.instant('Complete your signup'),
        isCompleted: this.completedSteps ? this.isCompletedStep(ManagerOnboardingChecklistStep.COMPLETE_SIGNUP) : false,
      },
      {
        title: this.translate.instant('Setup your company’s organization'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(ManagerOnboardingChecklistStep.SETUP_ORGANIZATION_SETUP)
          : false,
        action: () => this.router.navigate(['account/settings/locations']),
      },
      {
        title: this.translate.instant('Add your employees'),
        isCompleted: this.completedSteps ? this.isCompletedStep(ManagerOnboardingChecklistStep.ADD_EMPLOYEES) : false,
        action: () => this.router.navigate(['employees']),
      },
      {
        title: this.translate.instant('Schedule an employee'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(ManagerOnboardingChecklistStep.SCHEDULE_EMPLOYEE)
          : false,
        action: () => this.router.navigate(this.scheduleLink),
      },
      {
        title: this.translate.instant('Verify your email address'),
        isCompleted: this.completedSteps ? this.isCompletedStep(ManagerOnboardingChecklistStep.VERIFY_EMAIL) : false,
        action: () => this.router.navigate(['my-account/login']),
      },
    ];
  }

  private get selfOnboardingChecklistItems(): ChecklistItemModel[] {
    const config = this.getSelfOnboardingSteps();

    // We don't iterate over the enum directly, to guarantee the order of the steps.
    const selfOnboardingStepList: SmbHospitalityOnboardingChecklistStepKeys[] = [
      SmbHospitalityOnboardingChecklistStep.SCHEDULE_SHIFT,
      SmbHospitalityOnboardingChecklistStep.REPEAT_SHIFT,
      SmbHospitalityOnboardingChecklistStep.AVAILABILITY_MANAGEMENT,
      SmbHospitalityOnboardingChecklistStep.SHIFT_EXCHANGE,
      SmbHospitalityOnboardingChecklistStep.OPTIMIZED_SCHEDULE,
      SmbHospitalityOnboardingChecklistStep.TIME_TRACKING,
      SmbHospitalityOnboardingChecklistStep.INVITE_TEAM,
    ];

    return selfOnboardingStepList
      .filter((step: SmbHospitalityOnboardingChecklistStep) => this.onboardingUtils.canAccessStep(step))
      .map((step) => config[step]);
  }

  //TODO: adjust this method to reduce the amount of lines
  private getSelfOnboardingSteps(): Record<SmbHospitalityOnboardingChecklistStep, ChecklistItemModel> {
    return {
      [SmbHospitalityOnboardingChecklistStep.SCHEDULE_SHIFT]: {
        title: this.translate.instant('Add your first shift'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(SmbHospitalityOnboardingChecklistStep.SCHEDULE_SHIFT)
          : false,
        // TODO this can potentially work for any employee view.
        // For a future iteration we should maybe make this more lenient in that it will stay on the current schedule view if it is compatible with the onboarding step.
        action: () => this.goToStep('/schedule/employee/week', SmbHospitalityOnboardingChecklistStep.SCHEDULE_SHIFT),
      },
      //TODO remove when removing TMP_SELF_ONBOARDING_OPTIMIZED_SCHEDULING_CHAPTER
      [SmbHospitalityOnboardingChecklistStep.REPEAT_SHIFT]: {
        title: this.translate.instant('Repeat a shift'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(SmbHospitalityOnboardingChecklistStep.REPEAT_SHIFT)
          : false,
        // TODO There is no guarantee that there is a shift to repeat. How do we deal with that case?
        action: () => this.goToStep('/schedule/employee/week', SmbHospitalityOnboardingChecklistStep.REPEAT_SHIFT),
      },
      //TODO remove when removing TMP_SELF_ONBOARDING_OPTIMIZED_SCHEDULING_CHAPTER
      [SmbHospitalityOnboardingChecklistStep.AVAILABILITY_MANAGEMENT]: {
        title: this.translate.instant('Availability Management'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(SmbHospitalityOnboardingChecklistStep.AVAILABILITY_MANAGEMENT)
          : false,
        action: () =>
          this.goToStep('/schedule/employee/week', SmbHospitalityOnboardingChecklistStep.AVAILABILITY_MANAGEMENT),
      },
      [SmbHospitalityOnboardingChecklistStep.SHIFT_EXCHANGE]: {
        title: this.translate.instant('Shift Exchange'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(SmbHospitalityOnboardingChecklistStep.SHIFT_EXCHANGE)
          : false,

        action: () => this.goToStep('/schedule/employee/week', SmbHospitalityOnboardingChecklistStep.SHIFT_EXCHANGE),
      },
      [SmbHospitalityOnboardingChecklistStep.OPTIMIZED_SCHEDULE]: {
        title: this.translate.instant('Optimised Scheduling'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(SmbHospitalityOnboardingChecklistStep.OPTIMIZED_SCHEDULE)
          : false,
        action: () =>
          this.goToStep('/schedule/employee/week', SmbHospitalityOnboardingChecklistStep.OPTIMIZED_SCHEDULE),
      },
      [SmbHospitalityOnboardingChecklistStep.TIME_TRACKING]: {
        title: this.translate.instant('Time Tracking'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(SmbHospitalityOnboardingChecklistStep.TIME_TRACKING)
          : false,
        action: () => this.goToStep('/timesheet/team/day', SmbHospitalityOnboardingChecklistStep.TIME_TRACKING),
      },
      [SmbHospitalityOnboardingChecklistStep.INVITE_TEAM]: {
        title: this.translate.instant('Invite your team'),
        isCompleted: this.completedSteps
          ? this.isCompletedStep(SmbHospitalityOnboardingChecklistStep.INVITE_TEAM)
          : false,
        action: () => this.router.navigate(['', { outlets: { modal: ['onboarding-invite-employees'] } }]),
      },
    };
  }

  private goToStep(url: string, step?: SmbHospitalityOnboardingChecklistStep): void {
    const query = {
      originator: 'onboarding',
    };
    if (step) query['onboardingStep'] = step;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([url, query])),
      this.router.navigate([url, { query }]);
  }
  /**
   * Userguides are set up in the backend with an optional singular step (i.e. the current/last completed step).
   * Rather than letting the backend properly support a user guide with multiple active steps, the onboarding checklist abuses the step field to store a comma separated list of steps.
   * We therefore need to transform the api response back and forth.
   */
  private transformUserGuideStepToChecklistSteps(input: string): OnboardingChecklistStep[] {
    if (input === '' || input === null) {
      return [];
    }
    return input.split(',').map((step) => step.trim() as OnboardingChecklistStep);
  }

  private transformChecklistStepsToUserGuideStep(input: OnboardingChecklistStep[]): string {
    return input.map((step) => step.trim()).join(',');
  }
}
