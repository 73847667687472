import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BadgeThemePalette } from '@sb/ui';

export enum OpenShiftStatusFilterTypes {
  REQUESTED = 'Requested',
  PENDING = 'Pending',
  DECLINED = 'Declined',
  ASSIGNED = 'Assigned',
  NOT_INVITED = 'Not invited',
  INCOMPATIBLE_EMPLOYEES = 'Incompatible employees',
}

// Only for translations
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const translations = [
  _('Requested'),
  _('Pending'),
  _('Declined'),
  _('Assigned'),
  _('Not invited'),
  _('Incompatible employees'),
];

export enum OpenShiftStatus {
  REQUESTED = 'REQUESTED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  ASSIGNED = 'ASSIGNED',
}

export const OPEN_SHIFT_STATUS_COLOR_MAP: Record<keyof typeof OpenShiftStatus, BadgeThemePalette> = {
  [OpenShiftStatus.REQUESTED]: BadgeThemePalette.blue,
  [OpenShiftStatus.PENDING]: BadgeThemePalette.yellow,
  [OpenShiftStatus.DECLINED]: BadgeThemePalette.red,
  [OpenShiftStatus.ASSIGNED]: BadgeThemePalette.green,
};
