import { OnboardingDialogAction } from '@app/+authenticated/shared/onboarding/onboarding-dialog/onboarding-dialog.model';
import { Features } from '@app/enums';

import { PlanType } from '../../+authenticated/+reports/shared/subscriptions/subscription.model';

export enum ManagerOnboardingChecklistStep {
  COMPLETE_SIGNUP = 'COMPLETE_SIGNUP',
  SETUP_ORGANIZATION_SETUP = 'SETUP_ORGANIZATION_SETUP',
  ADD_EMPLOYEES = 'ADD_EMPLOYEES',
  SCHEDULE_EMPLOYEE = 'SCHEDULE_EMPLOYEE',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

// TODO when moving these models to shiftbase/models packaging,
// rename these consts/enum to be more generic and not associated to only hospitality
// SHB-11513
export enum SmbHospitalityOnboardingChecklistStep {
  SCHEDULE_SHIFT = 'SCHEDULE_SHIFT',
  REPEAT_SHIFT = 'REPEAT_SHIFT',
  AVAILABILITY_MANAGEMENT = 'AVAILABILITY_MANAGEMENT',
  SHIFT_EXCHANGE = 'SHIFT_EXCHANGE',
  OPTIMIZED_SCHEDULE = 'OPTIMIZED_SCHEDULE',
  TIME_TRACKING = 'TIME_TRACKING',
  INVITE_TEAM = 'INVITE_TEAM',
}

export enum InternalOnboardingStep {
  // INVITE_TEAM
  CELEBRATION_INVITED = 'CELEBRATION_INVITED',
  CELEBRATION_SKIPPED = 'CELEBRATION_SKIPPED',
  // TIME_TRACKING
  TIME_TRACKING_DEMO = 'TIME_TRACKING_DEMO',
  TIME_TRACKING_SUMMARY = 'TIME_TRACKING_SUMMARY',
  // OPTIMIZED_SCHEDULE
  OPTIMIZED_SCHEDULE_DEMO = 'OPTIMIZED_SCHEDULE_DEMO',
  OPTIMIZED_SCHEDULE_SUMMARY = 'OPTIMIZED_SCHEDULE_SUMMARY',
}

export const smbHospitalityOnboardingChecklistStepConfiguration: Partial<
  Record<OnboardingChecklistStep, OnboardingChecklistStepConfiguration>
> = {
  [SmbHospitalityOnboardingChecklistStep.SCHEDULE_SHIFT]: {
    nextStep: {
      primary: SmbHospitalityOnboardingChecklistStep.REPEAT_SHIFT,
    },
  },
  [SmbHospitalityOnboardingChecklistStep.REPEAT_SHIFT]: {
    disableOnFeatureFlag: Features.TMP_SELF_ONBOARDING_OPTIMIZED_SCHEDULING_CHAPTER,
    nextStep: {
      secondary: SmbHospitalityOnboardingChecklistStep.AVAILABILITY_MANAGEMENT,
    },
  },
  [SmbHospitalityOnboardingChecklistStep.AVAILABILITY_MANAGEMENT]: {
    disableOnFeatureFlag: Features.TMP_SELF_ONBOARDING_OPTIMIZED_SCHEDULING_CHAPTER,
    nextStep: {
      primary: SmbHospitalityOnboardingChecklistStep.SHIFT_EXCHANGE,
    },
  },
  [SmbHospitalityOnboardingChecklistStep.SHIFT_EXCHANGE]: {
    disableOnFeatureFlag: Features.TMP_SELF_ONBOARDING_OPTIMIZED_SCHEDULING_CHAPTER,
    nextStep: {
      primary: SmbHospitalityOnboardingChecklistStep.OPTIMIZED_SCHEDULE,
    },
  },
  [SmbHospitalityOnboardingChecklistStep.OPTIMIZED_SCHEDULE]: {
    featureFlag: Features.TMP_SELF_ONBOARDING_OPTIMIZED_SCHEDULING_CHAPTER,
    nextStep: {
      primary: SmbHospitalityOnboardingChecklistStep.INVITE_TEAM,
      // TODO: Remove this and all logic associated to it when removing tmp_self_onboarding_optimized_scheduling_chapter
      skipped: SmbHospitalityOnboardingChecklistStep.TIME_TRACKING,
    },
  },
  [SmbHospitalityOnboardingChecklistStep.TIME_TRACKING]: {
    minimumPlanRequired: PlanType.BASIC,
    featureFlag: Features.TMP_SELF_ONBOARDING_TIME_TRACKING_CHAPTER,
    nextStep: {
      primary: SmbHospitalityOnboardingChecklistStep.INVITE_TEAM,
    },
  },
  [SmbHospitalityOnboardingChecklistStep.INVITE_TEAM]: {
    nextStep: {
      primary: InternalOnboardingStep.CELEBRATION_INVITED,
      secondary: InternalOnboardingStep.CELEBRATION_SKIPPED,
    },
  },
};

export type OnboardingStep = SmbHospitalityOnboardingChecklistStep | InternalOnboardingStep;

export type ManagerOnboardingChecklistStepKeys = keyof typeof ManagerOnboardingChecklistStep;
export type SmbHospitalityOnboardingChecklistStepKeys = keyof typeof SmbHospitalityOnboardingChecklistStep;

export type OnboardingChecklistStep = ManagerOnboardingChecklistStepKeys | SmbHospitalityOnboardingChecklistStepKeys;

interface OnboardingChecklistStepConfiguration {
  minimumPlanRequired?: PlanType;
  disableOnFeatureFlag?: Features;
  featureFlag?: Features;
  nextStep?: {
    [key in OnboardingDialogAction | 'skipped']?: OnboardingStep;
  };
}
