import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionModel } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import {
  OnboardingDialogAction,
  OnboardingDialogResult,
} from '@app/+authenticated/shared/onboarding/onboarding-dialog/onboarding-dialog.model';
import { SelfOnboardingSegment } from '@app/enums';
import { AppState } from '@app/reducers';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { TrackingService } from '@app/services/tracking.service';
import {
  OnboardingStep,
  SmbHospitalityOnboardingChecklistStep,
  smbHospitalityOnboardingChecklistStepConfiguration,
} from '@app/shared/onboarding/onboarding-checklist.model';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { FeatureService } from '@app/startup/feature.service';
import { select, Store } from '@ngrx/store';
import { TrackingEvent } from '@shiftbase-com/models';
import { filter, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingUtils {
  public currentOnboardingStep$ = new Subject<OnboardingStep>();

  private subscription: SubscriptionModel;

  public constructor(
    private readonly store: Store<AppState>,
    private readonly trackingService: TrackingService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
    private readonly featureService: FeatureService,
  ) {
    void this.store
      .pipe(
        select(getAccountSubscription),
        filter((subscription) => !!subscription),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((subscription: SubscriptionModel) => {
        this.subscription = subscription;
      });
  }

  public nextOnboardingStep(isOnboardingFlow: boolean, callback: () => void): void {
    if (isOnboardingFlow && callback) {
      callback();
    } else {
      this.stripOnboardingParamsFromRoute();
    }
  }

  public highlightAndTrack(
    tracking: {
      event: TrackingEvent;
      onboardingSegment: SelfOnboardingSegment;
    },
    highlightElement?: HTMLElement,
  ) {
    if (highlightElement) {
      highlightElement.classList.add('self-onboarding-highlight');
    }
    this.trackingService.event(tracking.event, { onboarding_segment: tracking.onboardingSegment });
  }

  public stripOnboardingParamsFromRoute(): Promise<boolean> {
    const { originator, onboardingStep, ...params } = this.activatedRoute.snapshot.params;
    return this.router.navigate(['.', params], { relativeTo: this.activatedRoute });
  }

  public onFinishDialogClosed(result: OnboardingDialogResult, selfOnboardingSegment: SelfOnboardingSegment) {
    this.stripOnboardingParamsFromRoute().then(() => {
      switch (result.action) {
        case 'primary':
          this.trackingService.event('Onboarding Scheduling Exploring Continued', {
            onboarding_segment: selfOnboardingSegment,
          });
          break;
        case 'secondary':
          this.trackingService.event('Onboarding Scheduling Help Center Opened', {
            onboarding_segment: selfOnboardingSegment,
          });
          this.router.navigate(['', { outlets: { modal: ['support'] } }]);
          break;
      }
    });
  }

  public finishStep(
    currentStep: OnboardingStep,
    action: OnboardingDialogAction,
    isOnboardingFlow: boolean,
    skipped = false,
  ) {
    const config = smbHospitalityOnboardingChecklistStepConfiguration[currentStep];
    const nextStepMap = config?.nextStep;

    const nextStep = this.getNextStep(nextStepMap, action, skipped);

    if (!isOnboardingFlow || !nextStepMap || !nextStep) {
      this.stripOnboardingParamsFromRoute();
      return;
    }

    if (this.canAccessStep(nextStep)) {
      this.currentOnboardingStep$.next(nextStep);
    } else {
      this.finishStep(nextStep, action, isOnboardingFlow, true);
    }
  }

  private getNextStep(nextStepMap: OnboardingStep, action: OnboardingDialogAction | 'skipped', skipped: boolean) {
    let nextStep = nextStepMap?.[action];

    if (skipped && nextStepMap) {
      nextStep = nextStepMap['skipped'];
      if (!nextStep) {
        const firstStep = Object.keys(nextStepMap)[0];
        nextStep = nextStepMap[firstStep];
      }
    }

    return nextStep;
  }

  public canAccessStep(step: SmbHospitalityOnboardingChecklistStep) {
    const minimumPlanRequired = smbHospitalityOnboardingChecklistStepConfiguration[step]?.minimumPlanRequired;
    const featureFlag = smbHospitalityOnboardingChecklistStepConfiguration[step]?.featureFlag;
    const disableOnFeatureFlag = smbHospitalityOnboardingChecklistStepConfiguration[step]?.disableOnFeatureFlag;

    if (disableOnFeatureFlag && this.featureService.isFeatureActivated(disableOnFeatureFlag)) {
      return false;
    }

    if (!featureFlag || (featureFlag && this.featureService.isFeatureActivated(featureFlag))) {
      if (!minimumPlanRequired) return true;
      return hasAtleastSubscriptionPlan(minimumPlanRequired, this.subscription);
    }
    return false;
  }
}
